<template>
  <v-list-group :value="true">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="black--text font-weight-bold my-0 py-3">
          Currency
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item class="py-5 light-blue lighten-5 d-block">
      <v-select
        :items="['Podduang(XPD)','BINANCE(BNB)']"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        label="Currency"
        solo
        flat
        outlined
        hide-details
        class="items-select font-weight-bold mb-4"
      />

      <section class="d-flex align-center">
        <v-text-field
          v-model.number="min"
          hide-details
          solo
          flat
          single-line
          type="number"
          class="disable-prepend-inner-margin text-left"
          label="min"
          placeholder="0"
        />
        <p class="mb-0 mx-3">to</p>
        <v-text-field
          v-model.number="max"
          hide-details
          solo
          flat
          single-line
          type="number"
          class="disable-prepend-inner-margin text-left"
          label="Max"
          placeholder="0"
        />
      </section>
      <section class="d-flex align-center">
      <v-btn
        color="primary"
        class="mt-5"
        @click="apply"
        :disabled="invalidValues"
      >
        Apply
      </v-btn>
      <v-btn
        color="primary"
        class="mt-5 ml-3 "
        @click="clear"
        :disabled="!clearable"
      >
        Clear
      </v-btn>
      </section>
    </v-list-item>
  </v-list-group>
</template>

<script>
import BigNumber from 'bignumber.js';
import filtersComponentMixin from './mixins/filtersComponent';

export default {
  name: 'CurrencyFilter',
  components: {
  },
  data: () => ({
    search: null,
    min: null,
    max: null,
    clearable: false,
    currency: null,
    isLoading: false,
    currencies: [

    ],
  }),
  mixins: [filtersComponentMixin],
  computed: {
    invalidValues() {
      let active = false;
      if (this.max && this.min) active = (this.max >= 0 && this.min >= 0) && this.max > this.min;
      if (this.max && !this.min) active = this.max >= 0;
      if (!this.max && this.min) active = this.min >= 0;
      return !active;
    },
    formattedChains() {
      return this.chains.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
  },
  methods: {
    apply() {
      console.log('apply filter');
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams };
      this.clearable = true;
      if (this.max) mergedParams.maxVal = this.convertNum(this.max);
      else mergedParams.maxVal = '';
      if (this.min) mergedParams.minVal = this.convertNum(this.min);
      else mergedParams.minVal = '';
      mergedParams.p = 1;
      try {
        this.$router.replace({ query: mergedParams });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
    clear() {
      this.max = null;
      this.min = null;
      const currentParams = this.$router.currentRoute.query;
      const mergedParams = { ...currentParams };
      mergedParams.p = 1;
      mergedParams.maxVal = '';
      mergedParams.minVal = '';
      try {
        this.$router.replace({ query: mergedParams });
        this.clearable = false;
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
    convertNum(num) { // should have refractored to library
      let calculatedVal = BigNumber(num);
      calculatedVal = calculatedVal.multipliedBy(1000000000); // convert to eth
      return calculatedVal.toString();
    },
    convertBack(num) { // should have refractored to library
      let calculatedVal = BigNumber(num);
      calculatedVal = calculatedVal.dividedBy(1000000000); // convert to eth
      return calculatedVal.toString();
    },
  },
  mounted() {
    const currentParams = this.$router.currentRoute.query;
    const mergedParams = { ...currentParams };
    if (mergedParams.minVal) {
      this.min = this.convertBack(mergedParams.minVal);
      this.clearable = true;
    }
    if (mergedParams.maxVal) {
      this.max = this.convertBack(mergedParams.maxVal);
      this.clearable = true;
    }
  },
};
</script>
